div.questions {
    height: 100vh;
    width: 20vw;
    max-width: 320px;
    min-width: 240px;

    display: grid;
    grid-template-rows: auto 1fr;

    
}

div.question_header{
    height: 8vh;
    max-height: 48px;
    
    display: flex;
    align-items: center; 
    justify-content: center;
}

div.questions_content{
    padding-left: 5px;
    padding-right: 5px;

    font-family: 'Times New Roman', Times, serif;
    text-justify: auto;

    text-align: justify;

    overflow-y: scroll;
    
    p{
        // font-size: 14px;
        font-family: 'Times New Roman', Times, serif;
        text-justify: auto;
    }
    
    
}

div.updated_button{
    display: flex;
    justify-content: center;
    align-items: center;

    div{
        background-color: #FFD2E6;
        border-radius: 16px;
        position: absolute;
        bottom: 32px;
        // width: min-content;
        height: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            font-family: "mono_italic", mono;;
            font-size: 12px;
            margin: 0px 8px 0px 8px;
            
        }
        // margin-bottom: 32px;
        box-shadow: 0 0 12px 12px #FFD2E6;
    }
    
}

@media (max-width: 768px) {
    div.questions{
        display: none;
    }
}