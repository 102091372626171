div.worklist{
    padding-left: 16px;
    padding-right: 16px;
    display: grid;
    grid-template-columns: 1fr; /* Single column */
    overflow-y: scroll;
    height: 100%;
    grid-auto-rows: min-content;

    a{
        text-decoration: none;
        height: min-content;
    }
}

div.work{
    display: grid;
    grid-template-columns: 2fr 1fr 2fr; 
    column-gap: 32px;
}

div.communitylist{
    grid-template-columns: 2fr 1fr 1fr 2fr; 
    
}

div.work_dimensions{
    display: none;
}

div.work:hover{
   h1, h2{
    font-family: "mono_italic", mono;
   }
    h1{
        text-decoration: underline;
    }
}

@media (max-width: 768px) {
    div.work{
        display: grid;
        grid-template-columns: auto;
        margin-top: 16px;
        margin-bottom: 16px;
        
        
        div{
            padding: 0px;

            h1{
                margin-top: 0px;
                margin-bottom: 0px;
                font-family: "mono_italic", mono;
            }
            h2{
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }

}
