div.individual_work{
    display: grid;
    grid-template-rows: auto minmax(0, 1fr); 
   
    height: 100%; 
    overflow: hidden; 

       
}

div.individual_work_content{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0px;
    height: 100%;
    overflow: hidden;

    div.work{
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 16px;
        margin-bottom: 16px;
        text-align: left;

    div.work_dimensions{
        display: inline;
    }
    
    
    div{
        padding: 0px;

        h1{
            margin-top: 0px;
            margin-bottom: 4px;
            font-family: "mono_italic", mono;
        }
        h2{
            margin-top: 0px;
            margin-bottom: 4px;
        }
    }
        // background-color: rgba(25, 194, 194, .5);
        // backdrop-filter: blur(10px);
        
    }
    div.work:hover{
        h1, h2{
         font-family: "mono", mono;
        }
         h1{
             text-decoration: none;
         }
     }
    
    
}

div.work_description{
    width: 20vw; 
  max-width: 320px;
  min-width: 240px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: justify;
  
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
    
  p{
        // font-size: 14px;
        font-family: 'Times New Roman', Times, serif;
        text-justify: auto;
    }
    
}
div.work_description::-webkit-scrollbar {
    display: none;
  }
    

div.work_images{
    width: auto;
  height: 100%; 
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  justify-self: end;

  display: flex;
  flex-direction: column;

    img{
        max-width: 100%;
        max-height: 100%;
        align-self: end;

    }
}
div.work_images::-webkit-scrollbar {
    display: none;
  }

  
  @media (max-width: 768px) {
    div.individual_work{
        display: flex;
        flex-direction: column;
        overflow: visible;

        div.work{
            position: relative;
        }
    }

    div.individual_work_content{
        display: flex;
        flex-direction: column;
        
    }

    div.work_description{
      width: 100%; 
      text-align: justify;
      overflow-y: visible;
      max-width: fit-content;
      min-width: fit-content;  
    }
    div.work_images{
      width: fit-content;
      height: auto;
      overflow-y: visible;
        img{
            max-width: 100%;
            max-height: 100%;
        }
    }


    

  }