div.body{
    display: grid;
    grid-template-rows: auto 1fr;
    border-right: 1px solid black;
    height: 100vh;
}

@media (max-width: 768px) {
    div.body{
        height: auto;
        border: none;
    }
}
