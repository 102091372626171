div.textlist{
    display: grid;
    grid-template-columns: auto 1fr auto 1fr;
    overflow-y: scroll;
}

div.titles{
    width: 20vw;
    max-width: 320px;
    min-width: 240px;

    padding-left: 16px;
    padding-right: 16px;

    position: sticky;
    top: 0px;
    background-color: #F5F4F3;

    h5{
        margin: 0px;
        margin-top: 4px;
        // color: #EC6236;
        font-weight: 100;
        font-size: 12px;
    }
    h3{
        margin-bottom: 0px;
    }
}


div.bodytext{
    h5{
        margin: 16px;
        margin-left: 0px;
        font-weight: 100;
        font-size: 16px;
    }
    width: 40vw;
    // max-width: 320px;
    min-width: 400px;
    text-align: justify;

    p{
        font-size: 20px;
        font-family: 'Times New Roman', Times, serif;
        text-justify: auto;
    }
}
div.toread{
    text-align: left;
}

@media (max-width: 768px) {
    div.textlist{
        display: grid;
        grid-template-columns: 1fr;
        
    }
    div.bodytext{
        width: auto;
        // max-width: 320px;
        min-width: fit-content;
        text-align: justify;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 16px;
    
        p{
            font-size: 20px;
            font-family: 'Times New Roman', Times, serif;
            text-justify: auto;
            
        }
    }
}